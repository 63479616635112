@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Barlow+Condensed:wght@400;700&family=Bellefair&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  font-weight: 400;
}

:root {
  --dark: #0b0d17;
  --dark-2: #383b4b;
  --light-purple: #d0d6f9;
  --light: #fff;
  --light-op: rgba(255, 255, 255, 0.25);

  --nav-bg: rgba(255, 255, 255, 0.04);

  --font-primary: "Barlow", sans-serif;
  --font-secondary: "Barlow Condensed", sans-serif;
  --font-title: "Bellefair", serif;
}

body {
  font-family: var(--font-primary);
  color: var(--light);
  background-color: var(--dark);
  font-weight: 400;
  font-size: 18px;
  line-height: 178%;
}

h1 {
  font-family: var(--font-title);
  font-size: 150px;
  line-height: 115%;
}

h2 {
  font-family: var(--font-title);
  font-size: 100px;
  line-height: 115%;
}

h3 {
  font-family: var(--font-title);
  font-size: 56px;
  line-height: 115%;
  text-transform: uppercase;
}

h4,
button {
  font-family: var(--font-title);
  font-size: 32px;
  line-height: 115%;
  text-transform: uppercase;
}

button {
  letter-spacing: 2px;
  text-align: center;
  border: 0;
  outline: 0;
  background-color: var(--light);
  color: var(--dark);
}

h5 {
  font-family: var(--font-secondary);
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 4.725px;
}

.subtitle-1 {
  font-family: var(--font-secondary);
  font-size: 28px;
  line-height: 115%;
  text-transform: uppercase;
}

.subtitle-2 {
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 2.3625px;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.nav-text {
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 2.7px;
  text-transform: uppercase;
}

@media (max-width: 548px) {
  body {
    font-size: 15px;
    line-height: 167%;
  }

  h1 {
    font-size: 80px;
    line-height: 125%;
  }

  h2 {
    font-size: 80px;
  }

  h3 {
    font-size: 40px;
  }

  h4 {
    font-size: 24px;
  }

  button {
    font-size: 20px;
    letter-spacing: 1.25px;
  }

  h5 {
    font-size: 16px;
    letter-spacing: 2.7px;
  }

  .subtitle-1 {
    font-family: var(--font-secondary);
    font-size: 28px;
    line-height: 115%;
    text-transform: uppercase;
  }
}
